import "core-js/stable"
import "regenerator-runtime/runtime"
import * as React from "react"
import * as ReactDOM from "react-dom"
import "../utils/axios"
import SortableChecklist from "../components/SortableChecklist"

document.addEventListener("turbolinks:load", () => {
  const rootDiv: HTMLElement | null = document.body.querySelector("#checklist-sortable-list")

  if (rootDiv) {
    const checklist: Attr | { value: string } = rootDiv.attributes.getNamedItem("data-checklist") || {
      value: "[]",
    }

    const checklistManageable: Attr | { value: string } = rootDiv.attributes.getNamedItem(
      "data-manageable"
    ) || {
      value: "",
    }

    ReactDOM.render(
      <SortableChecklist
        manageable={JSON.parse(checklistManageable.value)}
        checklist={JSON.parse(checklist.value)}
      />,
      rootDiv
    )
  }
})
