import React, { useMemo, useState, useRef } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { populateArrayObjectsWithIdentifiers, populateObjectWithIdentifier } from "../utils/react"

/**
 * Reorder utility function
 *
 * @param list - to be re-ordered
 * @param startIndex - initial index of an element
 * @param endIndex - final index of an element
 */
const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

/**
 * Checklist item type
 */
export type ChecklistItem = {
  stages: ["CHECKIN"] | ["CHECKOUT"] | ["CHECKIN", "CHECKOUT"] | []
  name: string
}

/**
 * Checklist container component props type
 */
type SortableChecklistProps = {
  checklist: ChecklistItem[]
  manageable: boolean
}

/**
 * Checklist item component props type
 */
type SortableChecklistItemProps = {
  item: ChecklistItem
  index: number
  manageable: boolean
}

/**
 * Checklist item component
 *
 * @param item - json data of an item to be rendered
 * @param index - initial index of an item in an array
 * @param manageable - whether the item can be modified or not
 */
const SortableChecklistItem: React.FC<SortableChecklistItemProps> = ({ item, index, manageable }) => {
  const [itemName, setItemName] = useState(item.name)

  const nameLabel = useRef<HTMLLabelElement>(null)

  const focusNameLabel = () => {
    if (nameLabel.current !== null) {
      const range = document.createRange()
      range.setStart(nameLabel.current, 1)
      range.collapse(true)

      const selection = window.getSelection()
      selection?.removeAllRanges()
      selection?.addRange(range)

      nameLabel.current!.focus()
    }
  }

  return (
    <div className="form-group mb-3 checklist-sortable-list-item">
      <label
        className="font-weight-bold item_name_label"
        htmlFor={`checklist[items][${index}][name]`}
        contentEditable={manageable}
        ref={nameLabel}
        onBlur={(e) => {
          setItemName(e.currentTarget.textContent || "")
        }}
        tabIndex={0}
        dangerouslySetInnerHTML={{ __html: itemName }}
      />{" "}
      <i className="ti-pencil" onClick={focusNameLabel}></i>
      <input type="hidden" name={`checklist[items][${index}][name]`} value={itemName} />
      <div className="checklist-sortable-list-drag-container">
        <div>
          <label className="checkmark-container">
            <input
              type="radio"
              name={`checklist[items][${index}][stages]`}
              value='["CHECKIN"]'
              defaultChecked={item.stages.length === 1 && item.stages[0] === "CHECKIN"}
            />
            In
            <span className="checkmark check-pass bg-success" />
          </label>
          <label className="checkmark-container">
            <input
              type="radio"
              name={`checklist[items][${index}][stages]`}
              value='["CHECKOUT"]'
              defaultChecked={item.stages.length === 1 && item.stages[0] === "CHECKOUT"}
            />
            Out
            <span className="checkmark check-pass bg-success" />
          </label>
          <label className="checkmark-container">
            <input
              type="radio"
              name={`checklist[items][${index}][stages]`}
              value='["CHECKIN", "CHECKOUT"]'
              defaultChecked={item.stages.length === 2}
            />
            Both
            <span className="checkmark check-pass bg-success" />
          </label>
          <label className="checkmark-container">
            <input
              type="radio"
              name={`checklist[items][${index}][stages]`}
              value="[]"
              defaultChecked={item.stages.length === 0}
            />
            None
            <span className="checkmark check-pass bg-dark" />
          </label>
        </div>
        <i className="ti-arrows-vertical"></i>
      </div>
    </div>
  )
}

/**
 * Checklist container component
 *
 * @param checklist - an array of checklist items to be rendered
 * @param manageable - whether checklist item names could be re-ordered or not
 */
const SortableChecklist: React.FC<SortableChecklistProps> = ({ checklist, manageable }) => {
  const optimizedArray = useMemo(
    () => populateArrayObjectsWithIdentifiers(checklist),
    // eslint-disable-next-line
    []
  )

  const [stateArray, setStateArray] = useState(
    optimizedArray.map((checklistItem, index) => ({ ...checklistItem, _initialIndex: index }))
  )

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    setStateArray(reorder(stateArray, result.source.index, result.destination.index))
  }

  const addNewItem = () => {
    setStateArray([
      ...stateArray,
      populateObjectWithIdentifier({
        _initialIndex: stateArray.length,
        name: "Item name",
        stages: ["CHECKIN"],
      }) as any,
    ])
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="checklist-sortable-list">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {stateArray.map((item, index) => (
                <Draggable key={item._identifier} draggableId={item._identifier} index={index}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <SortableChecklistItem
                        manageable={manageable}
                        item={item as any}
                        index={item._initialIndex}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="mb-5">
        <button type="button" className="btn btn-primary btn-sm mt-3" onClick={addNewItem}>
          Add Custom Item
        </button>
      </div>
    </>
  )
}

export default SortableChecklist
