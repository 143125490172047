import axios from "axios"
import delay from "delay"
import pRetry from "p-retry"

axios.defaults.withCredentials = true

const instance = axios.create({
  baseURL: "/api/v1",
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
})

instance.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status === 401) {
      window.location.assign("/users/sign_in")
      await delay(5000)
    }
    return Promise.reject(error)
  }
)

window["axios"] = instance

export default instance

export const retry = (fn: () => any, options = {}) =>
  pRetry(fn, {
    retries: 4,
    minTimeout: 250,
    factor: 1.4,
    onFailedAttempt: (error: any) => {
      if (error.response?.status === 404) {
        // Never retry on a 404 error
        throw error
      }
    },
    ...options,
  })
