import { v4 as uuidv4 } from "uuid"

/**
 * Object with unique identifier type
 */
type ObjectWithIdentifier = {
  _identifier: string
  [key: string]: any
}

/**
 * Utility function to populate a single object with an unique identifier
 *
 * @param object - to be extended
 */
const populateObjectWithIdentifier = (object: object): ObjectWithIdentifier => ({
  ...object,
  _identifier: uuidv4(),
})

/**
 * Utility function to populate an array of objects with unique identifiers
 *
 * @param array - object of which will be extended
 */
const populateArrayObjectsWithIdentifiers = (array: object[]): ObjectWithIdentifier[] =>
  array.map((object) => populateObjectWithIdentifier(object))

export { populateArrayObjectsWithIdentifiers, populateObjectWithIdentifier }
